export const langMap = {
  admin: "Admin",
  doctor: "Doctor",
  assistant: "Asistente",
};
export const langMapAbr = {
  admin: "Adm",
  doctor: "Dr",
  assistant: "Ast",
};

export function castRole(word?: string, abr = false): string | undefined {
  if (!word) return undefined;
  const map = abr ? langMapAbr : langMap;
  if (map.hasOwnProperty(word)) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return map[word] as string;
  } else {
    return undefined;
  }
}

// import { UserButton } from "@clerk/nextjs";
import routes from "@/utils/routes";

import { LanguagePicker } from "@/components";
import { useDisclosure } from "@mantine/hooks";
import Image from "next/image";
// import { useFullscreen } from "@mantine/hooks";
import {
  AppShell,
  Burger,
  Group,
  Button,
  Text,
  em,
  Container,
  Box,
  UnstyledButton,
  Flex,
  Anchor,
  Center,
  darken,
} from "@mantine/core";
import Link from "next/link";
import { useMediaQuery } from "@mantine/hooks";
import React from "react";
import { useTranslation } from "react-i18next";
interface Props {
  children?: React.ReactNode | React.ReactNode[];
}
export function PublicLayout({ children }: Props) {
  const isMobile = useMediaQuery(`(max-width: ${em(750)})`);

  const { t } = useTranslation();
  // const theme = useMantineTheme();
  const [opened, { toggle }] = useDisclosure();
  return (
    <AppShell
      header={{ height: 72 }}
      navbar={{
        width: 300,
        breakpoint: "md",
        collapsed: { desktop: true, mobile: !opened },
      }}
      footer={{
        height: 60,
      }}
      padding="md"
    >
      <AppShell.Header>
        <Container h="100%" size="xl">
          <Group h="100%" justify="space-between">
            <UnstyledButton component="a" href="/" h={28}>
              <Image
                src="/logos/full-logo-blue.svg"
                alt="Alma Obstetrician Healthcare Software"
                height={28}
                width={100}
              />
            </UnstyledButton>
            <Box>
              <Group>
                <Box hiddenFrom="md">
                  <LanguagePicker type="collapsed" />
                </Box>
                <Burger
                  opened={opened}
                  onClick={toggle}
                  hiddenFrom="md"
                  size="sm"
                />
              </Group>
              <Group
                visibleFrom="md"
                justify="space-between"
                style={{ flex: isMobile ? 0 : 1 }}
              >
                <Group ml="xl" gap={4} visibleFrom="sm">
                  <Button
                    component="a"
                    href={routes.calc}
                    variant="subtle"
                    color="black"
                  >
                    {t("calculator")}
                  </Button>
                  <LanguagePicker type="collapsed" />
                </Group>
                {/* <UserButton /> */}
              </Group>
            </Box>
          </Group>
        </Container>
      </AppShell.Header>

      <AppShell.Navbar py="md" px={4} h="100%">
        <Button component="a" href={routes.calc} variant="subtle" color="black">
          {t("calculator")}
        </Button>
      </AppShell.Navbar>

      <AppShell.Main p={0} pt={72}>
        {children}
      </AppShell.Main>

      <AppShell.Footer
        pos="initial"
        styles={(theme) => ({
          footer: {
            background: darken(theme.colors.blue[9], 0.2),
            color: "white",
          },
        })}
      >
        <Container
          size="xl"
          p="sm"
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Flex gap={4} mb={2}>
            <Link href="/terms" style={{ textDecoration: "unset" }}>
              <Text size="xs" c="white">
                {t("termsAndconditions")}
              </Text>
            </Link>

            <Text size="xs" c="white">
              |
            </Text>
            <Anchor
              size="xs"
              href="mailto:info@alma-med.com"
              target="_blank"
              c="white"
            >
              {t("contact")}
            </Anchor>
          </Flex>
          <Text size="11px" c="dark.1">
            Copyright ©{new Date().getFullYear()} Alma Med{" "}
            {isMobile ? "" : `- ${t("allRightsReserved")}`}
          </Text>
        </Container>
      </AppShell.Footer>
    </AppShell>
  );
}

// import { useDisclosure } from "@mantine/hooks";
import { AppShell, Burger, Group, Box } from "@mantine/core";
import { ToggleColorSchemeButton } from "@/components/ToggleColorSchemeButton";
import { UserButton } from "@clerk/nextjs";
import { LanguagePicker } from "@/components";
interface Props {
  children?: React.ReactElement | React.ReactElement[];
  opened: boolean;
  toggle: () => void;
}
export function Header({ opened, toggle }: Props) {
  return (
    <AppShell.Header>
      <Group h="100%" px="md">
        <Burger opened={opened} onClick={toggle} hiddenFrom="sm" size="sm" />
        <Box style={{ marginLeft: "auto" }}>
          <Group display="flex" gap="md">
            <ToggleColorSchemeButton />
            <LanguagePicker type="collapsed" />
            <UserButton afterSignOutUrl="/" />
          </Group>
        </Box>
      </Group>
    </AppShell.Header>
  );
}

interface Props {
  accessRoles: string[];
  children: React.ReactElement | React.ReactElement[];
}

export function RenderRole({ accessRoles, children }: Props) {
  // const auth = { currentUser: { roles: ["doctor"] } }; //TODO: GET user
  // if (
  //   auth?.currentUser?.roles?.some((userRole) => accessRoles.includes(userRole))
  // )
  //   return children;
  // return null;
  return children;
}

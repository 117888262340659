import { useRouter } from "next/router";
import { type ReactNode } from "react";
import { routes } from "@/utils";
import {
  Avatar,
  Group,
  Text,
  UnstyledButton,
  type UnstyledButtonProps,
} from "@mantine/core";
import { IconChevronRight } from "@tabler/icons-react";

type UserProfileButtonProps = {
  image?: string;
  name: string;
  email?: string;
  icon?: ReactNode;
  role?: string;
} & UnstyledButtonProps;

export const UserButton = ({
  image,
  name,
  email,
  role,
  ...others
}: UserProfileButtonProps) => {
  const router = useRouter();
  return (
    <UnstyledButton
      w="100%"
      {...others}
      onClick={() => router.push(routes.profile)}
    >
      <Group gap={4}>
        <Avatar src={image} radius="xl" color="white" />

        <div style={{ flex: 1 }}>
          <Text w="fit-content" size="sm">
            {role} {name}
          </Text>

          <Text w="fit-content" size="xs">
            {email}
          </Text>
        </div>

        <IconChevronRight size="0.9rem" stroke={1.5} />
      </Group>
    </UnstyledButton>
  );
};

import React from "react";
import { api } from "@/utils/api";
import { Select, Loader, type SelectProps } from "@mantine/core";

import { useTranslation } from "react-i18next";

interface Props extends SelectProps, React.RefAttributes<HTMLInputElement> {}

export function RoleSelector(props: Props) {
  const { t } = useTranslation();
  const { data, isLoading } = api.roles.getAll.useQuery();
  const transformedData = data
    ? data?.map((role) => ({ value: role.id, label: t(role.name) }))
    : [];

  return (
    <Select
      {...props}
      rightSection={isLoading ? <Loader size="xs" /> : null}
      data={transformedData}
    />
  );
}

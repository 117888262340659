import { useState } from "react";

import {
  Box,
  Collapse,
  Group,
  rem,
  Text,
  UnstyledButton,
  useMantineColorScheme,
} from "@mantine/core";
import {
  IconCalendarStats,
  IconChevronLeft,
  IconChevronRight,
} from "@tabler/icons-react";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";

import Link from "next/link";
import { redirect } from "next/navigation";

import { type TablerIconsProps } from "@tabler/icons-react";
import { RenderRole } from "@/components";

// import useStyles from './Links.styles'

interface LinksGroupProps {
  icon: React.FC<TablerIconsProps>;
  label: string;
  initiallyOpened?: boolean;
  link?: string;
  links?: {
    label: string;
    link: string;
    icon: React.FC<TablerIconsProps>;
    accessRoles: string[];
  }[];
  hidden?: boolean;
  accessRoles: string[];
}

export function LinksGroup({
  icon: Icon,
  label,
  initiallyOpened,
  link,
  links,
  hidden,
  accessRoles,
}: LinksGroupProps) {
  // const { classes, theme } = useStyles()
  const hasLinks = Array.isArray(links);
  const { t } = useTranslation();
  const [opened, setOpened] = useState(initiallyOpened ?? false);
  const { colorScheme } = useMantineColorScheme();
  const ChevronIcon = IconChevronRight; //: IconChevronLeft;
  const router = useRouter();
  const activePath = router.pathname;

  const items = (hasLinks ? links : []).map((link) => (
    <RenderRole key={link.label} accessRoles={link.accessRoles}>
      <Text
        component={Link}
        styles={(theme) => ({
          root: {
            fontWeight: 500,
            display: "block",
            textDecoration: "none",
            padding: `${theme.spacing.xs} ${theme.spacing.md}`,
            paddingLeft: rem(15),
            marginLeft: rem(28),
            fontSize: theme.fontSizes.sm,
            color: theme.white,
            borderLeft: `${rem(1)} solid ${
              colorScheme === "dark"
                ? theme.colors.dark[4]
                : theme.colors.gray[3]
            }`,

            "&:hover": {
              backgroundColor: theme.colors[theme.primaryColor]![8],
            },
          },
        })}
        href={link.link}
        style={{ display: "flex" }}
      >
        <link.icon size={18} style={{ marginRight: 4 }} />
        {t(link.label)}
      </Text>
    </RenderRole>
  ));

  return (
    <>
      <RenderRole accessRoles={accessRoles}>
        <UnstyledButton
          onClick={async (evt) => {
            setOpened((o) => !o);
            if (link && activePath !== link) await router.push(link);
          }}
          styles={(theme) => ({
            root: {
              display: "block",
              width: "100%",
              padding: `${theme.spacing.xs} ${theme.spacing.md}`,
              color: theme.white,
              fontSize: theme.fontSizes.sm,
              fontWeight: activePath === link ? "700" : "500",
              "&:hover": {
                backgroundColor: theme.colors[theme.primaryColor]![8],
              },
            },
          })}
        >
          <Group gap={0}>
            <Box style={{ display: "flex", alignItems: "center" }}>
              <Icon size={18} />
              {!hidden && <Box ml="md">{t(label)}</Box>}
              {/* {!hidden && <Box ml="md">{label}</Box>} */}
            </Box>
            {hasLinks && (
              <ChevronIcon
                size="1rem"
                stroke={1.5}
                style={{
                  transition: "transform 200ms ease",
                  transform: opened ? `rotate(90deg)` : "none",
                }}
              />
            )}
          </Group>
        </UnstyledButton>
        <>{hasLinks ? <Collapse in={opened}>{items}</Collapse> : null}</>
      </RenderRole>
    </>
  );
}

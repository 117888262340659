import { useDisclosure } from "@mantine/hooks";
import { AppShell } from "@mantine/core";
import { Navbar, Header } from "@/components";

interface Props {
  children?: React.ReactElement | React.ReactElement[];
}
export function Layout({ children }: Props) {
  const [opened, { toggle }] = useDisclosure();

  return (
    <AppShell
      layout="alt"
      header={{ height: 60 }}
      navbar={{
        width: 260,
        breakpoint: "sm",
        collapsed: { mobile: !opened },
      }}
      padding="md"
    >
      <Header opened={opened} toggle={toggle} />
      <Navbar opened={opened} toggle={toggle} />
      <AppShell.Main>{children}</AppShell.Main>
    </AppShell>
  );
}

import {
  useMantineColorScheme,
  AppShell,
  Group,
  Text,
  ScrollArea,
  Box,
  ActionIcon,
  LoadingOverlay,
  TextInput,
  rem,
} from "@mantine/core";
import { useTranslation } from "react-i18next";
import { castRole } from "@/utils/langMap";
import {
  IconAdjustmentsFilled,
  IconSettings,
  IconUsers,
  IconUserPlus,
  IconLayersLinked,
  IconPlugConnected,
  IconAddressBook,
  IconCalendar,
  IconCalendarPlus,
  IconListCheck,
  IconChevronLeft,
  IconReceipt2,
  IconSearch,
} from "@tabler/icons-react";
import Image from "next/image";
import { routes } from "@/utils";
import { UserButton } from "@/components";
interface Props {
  children?: React.ReactElement | React.ReactElement[];
  opened: boolean;
  toggle: () => void;
}
import { LinksGroup } from "./LinksGroup/LinksGroup";
import { api } from "@/utils/api";

const data = [
  {
    title: "menu",
    links: [
      {
        accessRoles: ["doctor", "assistant"],
        label: "dashboard",
        icon: IconAdjustmentsFilled,
        link: routes.app,
      },
      {
        label: "patients",
        icon: IconUsers,
        accessRoles: ["doctor", "assistant"],
        links: [
          {
            label: "myPatients",
            icon: IconAddressBook,
            link: routes.myPatients,
            accessRoles: ["doctor"],
          },
          {
            label: "newPatient",
            icon: IconUserPlus,
            link: routes.newPatient,
            accessRoles: ["doctor", "assistant"],
          },
          {
            label: "linkPatient",
            icon: IconPlugConnected,
            link: routes.linkPatient,
            accessRoles: ["doctor", "assistant"],
          },
        ],
      },

      {
        label: "settings",
        icon: IconSettings,
        accessRoles: ["doctor", "assistant"],
        links: [
          {
            label: "generalConfig",
            icon: IconSettings,
            link: routes.settings,
            accessRoles: ["doctor", "assistant"],
          },
          {
            label: "payments&suscription",
            // icon: IconCash,
            icon: IconReceipt2,
            link: routes.app,
            accessRoles: ["doctor"],
          },
          {
            label: "myAssistants",
            icon: IconLayersLinked,
            link: routes.myAssistants,
            accessRoles: ["doctor"],
          },
        ],
      },
      {
        label: "agenda",
        icon: IconListCheck,
        accessRoles: ["doctor", "assistant"],
        links: [
          {
            label: "calendar",
            icon: IconCalendar,
            link: routes.app,
            accessRoles: ["doctor", "assistant"],
          },
          {
            label: "newAppointment",
            icon: IconCalendarPlus,
            link: routes.app,
            accessRoles: ["doctor"],
          },
        ],
      },
    ],
  },
  // {
  //   title: 'Documentation',
  //   links: [
  //     {
  //       label: 'Getting started',
  //       icon: IconLifebuoy,
  //       link: PATH_DOCS.root,
  //     },
  //     {
  //       label: 'Documentation',
  //       icon: IconBook2,
  //       link: PATH_DOCS.root,
  //     },
  //     { label: 'Changelog', icon: IconList },
  //   ],
  // },
];
export function Navbar({ children, toggle }: Props) {
  const { t } = useTranslation();
  const { data: practitioner, isLoading } = api.practitioners.me.useQuery();

  const { colorScheme } = useMantineColorScheme();

  const links = data.map((m) => (
    <Box pl={0} mb="md" key={m.title}>
      <Text tt="uppercase" size="xs" pl="md" fw={500} mb="sm" c="gray.2">
        {t(`${m.title}`)}
      </Text>
      {m.links.map((item) => (
        <LinksGroup {...item} key={item.label} />
      ))}
    </Box>
  ));
  return (
    <AppShell.Navbar
      styles={(theme) => {
        return {
          navbar: {
            backgroundColor:
              colorScheme === "dark"
                ? theme.colors.dark[7]
                : theme.colors[theme.primaryColor]![9],
          },
        };
      }}
    >
      <Group
        gap={6}
        styles={(theme) => ({
          root: {
            height: 60,
            paddingLeft: theme.spacing.md,
            paddingRight: theme.spacing.md,
            backgroundColor:
              colorScheme === "dark"
                ? theme.colors.dark[7]
                : theme.colors[theme.primaryColor]![9],
            borderBottom:
              colorScheme === "dark"
                ? `1px solid ${theme.colors.gray[8]}`
                : "none",
          },
        })}
      >
        {colorScheme === "dark" ? (
          <Image
            src="/logos/full-logo-white.svg"
            alt=""
            height={38}
            width={140}
          />
        ) : (
          <Image
            src="/logos/full-logo-white.svg"
            alt=""
            height={38}
            width={140}
          />
        )}

        {/* <Text size="lg" fw={700} c="gray.2">
          Alma
        </Text> */}
        <ActionIcon
          variant="transparent"
          style={{ marginLeft: "auto" }}
          hiddenFrom="sm"
          size="md"
          onClick={toggle}
          c="gray.2"
        >
          <IconChevronLeft />
        </ActionIcon>
      </Group>

      <Box p="md">
        <TextInput
          placeholder="Search"
          size="xs"
          leftSection={
            <IconSearch
              style={{ width: rem(12), height: rem(12) }}
              stroke={1.5}
            />
          }
          rightSectionWidth={70}
          styles={{ section: { pointerEvents: "none" } }}
          mb="sm"
        />
      </Box>
      <Box p="md">
        <ScrollArea
          styles={(theme) => ({
            root: {
              marginLeft: `calc(${theme.spacing.md} * -1)`,
              marginRight: `calc(${theme.spacing.md} * -1)`,
              paddingBottom: theme.spacing.xl,
            },
          })}
        >
          {links}
        </ScrollArea>
      </Box>

      <Box style={{ marginTop: "auto" }} px="md" pos="relative">
        <LoadingOverlay visible={isLoading} />
        <UserButton
          email={practitioner?.email}
          image={
            practitioner?.hasImage && practitioner?.imageUrl
              ? practitioner?.imageUrl
              : undefined
          }
          name={`${practitioner?.lastName}, ${practitioner?.firstName}`}
          role={castRole(practitioner?.roles?.[0]?.name, true)}
          styles={(theme) => ({ root: { color: theme.white } })}
        />
      </Box>
    </AppShell.Navbar>
  );
}
